import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BackgroundText, ContentWithLink, HomePageHero, Logos, MeetTheTeam, NavBarColorSwitcher, Spacer, Words } from "components";
import planning from "images/team-images/planning.jpg";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import sofa2 from "images/team-images/sofa2.png";
import sofa from "images/team-images/sofa.jpg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wupwt from "images/client-logos/wupwt.png";
export const _frontmatter = {
  "navBarColor": "light"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <HomePageHero headline="Heck" lines={["Why", "didn't we", "work", "together", "before"]} message="We are a software house that turns your **awesome idea** into a product" mdxType="HomePageHero" />
    <NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
	<ContentWithLink button={{
        children: "Check our work",
        to: "/about"
      }} content="We design, develop and maintain software built on top of WordPress and Laravel. You can grow your business at the same time." headline="We do **development** and **design**. And we do it right." headlineAlign="left" image={{
        src: sofa,
        title: "Department of Web Team"
      }} imagePosition="top" mdxType="ContentWithLink" />
	<Spacer height={110} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<BackgroundText offsetX={44} offsetY={-425} position="right" textAlign="right" responsive={{
        md: {
          offsetX: 120,
          offsetY: -200
        },
        sm: {
          offsetX: 136,
          offsetY: -150
        }
      }} mdxType="BackgroundText">
		WORK& PARTNERS
	</BackgroundText>
	<Logos logos={[{
        image: gpd,
        title: "Good People Digital"
      }, {
        image: insanelab,
        title: "InsaneLab"
      }, {
        image: thecamels,
        title: "The Camels"
      }, {
        image: wpserved,
        title: "WP Served"
      }, {
        image: fusebox,
        title: "Fusebox"
      }, {
        image: tnbt,
        title: "TNBT"
      }, {
        image: wupwt,
        title: "Wojewódzki Urząd Pracy w Toruniu"
      }]} logosAlign="center" title="Over 265+ client projects" titleAlign="center" mdxType="Logos" />
	<Spacer height={110} responsive={{
        md: 100,
        sm: 100
      }} mdxType="Spacer" />
	<Words lines={["Design", "Software", "Plugins"]} mdxType="Words" />
	<Spacer height={20} responsive={{
        md: 80,
        sm: 100
      }} mdxType="Spacer" />
	<BackgroundText offsetX={375} offsetY={-100} position="right" textAlign="right" responsive={{
        md: {
          offsetX: -12,
          offsetY: -300,
          position: "left",
          textAlign: "left"
        },
        sm: {
          offsetX: -12,
          offsetY: -270,
          position: "left",
          textAlign: "left"
        }
      }} mdxType="BackgroundText">
		WORD PRESS
	</BackgroundText>
	<MeetTheTeam button={{
        children: "Meet our team",
        to: "/about#team"
      }} images={[{
        src: sofa2,
        title: "Setting up the project"
      }, {
        src: planning,
        title: "Discussing the design"
      }]} text="Boutique agency providing <strong>end-to-end</strong> digital solutions." textAlign="left" mdxType="MeetTheTeam" />
	<Spacer height={140} responsive={{
        md: 120,
        sm: 90
      }} mdxType="Spacer" />
	<ContentWithLink button={{
        children: "Our Statement",
        to: "/our-work"
      }} content="We're passionate about the projects we do, just the same as our clients. It creates a unique relationship between us and our clients and helps us go above and beyond." headline="We **create**, develop and&nbsp;have&nbsp;**fun**." headlineAlign="left" mdxType="ContentWithLink" />
	<Spacer height={120} responsive={{
        md: 100,
        sm: 90
      }} mdxType="Spacer" />
    </NavBarColorSwitcher>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      